import React, { FC } from 'react';

import {
  useController,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';

import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';

type Props = CheckboxProps & UseControllerProps;

const Checkbox: FC<Props> = props => {
  const { control } = useFormContext();
  const { field } = useController({ control, ...props });

  return <MuiCheckbox {...props} {...field} checked={field.value} />;
};

export default Checkbox;
