import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';

type Props = Omit<MuiDialogProps, 'open'> & {
  activateButton: (open: () => void) => ReactNode;
};

export type DialogProps = Props;

type DialogContextType = {
  close: () => void;
};

const DialogContext = createContext<DialogContextType>({
  close: () => {},
});

export const useDialog = () => useContext(DialogContext);

const Dialog: FC<Props> = ({ children, activateButton, ...rest }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleDialogClose = useCallback((_: any, reason: any) => {
    if (reason === 'escapeKeyDown') {
      setIsOpen(false);
    }
  }, []);

  return (
    <DialogContext.Provider value={{ close }}>
      {activateButton(open)}

      <MuiDialog
        open={isOpen}
        onClose={handleDialogClose}
        scroll="body"
        {...rest}>
        {children}
      </MuiDialog>
    </DialogContext.Provider>
  );
};

export default Dialog;
