import BaseAvatar from 'components/BaseAvatar';
import { useCurrentUser } from 'features';

const Avatar = () => {
  const currentUser = useCurrentUser();

  return (
    <BaseAvatar
      firstName={currentUser?.firstName}
      lastName={currentUser?.lastName}
    />
  );
};

export default Avatar;
