import React, { FC, ReactNode } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Fab from '../Fab';
import Dialog, { DialogProps } from '../Dialog';

type Props = Omit<DialogProps, 'activateButton'> & {
  icon?: ReactNode;
};

const FabDialog: FC<Props> = ({ icon, children, ...rest }) => (
  <Dialog
    activateButton={open => <Fab icon={icon ?? <AddIcon />} onClick={open} />}
    {...rest}>
    {children}
  </Dialog>
);

export default FabDialog;
