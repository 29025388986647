import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import Alert, { AlertProps } from '@mui/material/Alert';

type Severity = AlertProps['severity'];

type Context = {
  pop: (text: string, severity?: Severity) => void;
};

const NotificationsContext = createContext<Context>({
  pop: () => {},
});

export const useNotification = () => useContext(NotificationsContext);

const origin: SnackbarOrigin = {
  horizontal: 'right',
  vertical: 'top',
};

type Props = {
  children?: ReactNode;
};

type Message = {
  text: string;
  severity?: Severity;
};

const Notifications: FC<Props> = ({ children }) => {
  const [message, setMessage] = useState<Message | null>(null);

  const pop = useCallback((text: string, severity: Severity = 'info') => {
    setMessage({ text, severity });
  }, []);

  const handleClose = useCallback(() => {
    setMessage(null);
  }, []);

  const value = useMemo(() => ({ pop }), [pop]);

  return (
    <NotificationsContext.Provider value={value}>
      {children}
      <Snackbar
        anchorOrigin={origin}
        open={!!message}
        autoHideDuration={6000}
        onClose={handleClose}>
        <Alert variant="filled" severity={message?.severity}>
          {message?.text}
        </Alert>
      </Snackbar>
    </NotificationsContext.Provider>
  );
};

export default Notifications;
