import React, { FC, ReactNode } from 'react';

import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';
import { IconProps } from '@mui/material/Icon';

export type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];
export type FontSize = PropType<IconProps, 'fontSize'>;

type Props = {
  icon?: ReactNode;
  tooltip?: string;
  fontSize?: FontSize;
};

const ConfirmableButtonConfirmIcon: FC<Props> = props => {
  const { icon, tooltip, fontSize } = props;

  const displayIcon = icon ?? <ErrorIcon fontSize={fontSize} />;

  // FIXME: @Cleanup Figure out why tooltip doesn't pop up
  return tooltip ? (
    <Tooltip title={tooltip}>
      <>{displayIcon}</>
    </Tooltip>
  ) : (
    <>{displayIcon}</>
  );
};

export default ConfirmableButtonConfirmIcon;
