import React, { FC, ReactNode } from 'react';

import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import AppBar from '../AppBar';
import UserArea from '../UserArea';
import ColorModeSwitch from '../ColorModeSwitch';

type Props = {
  open: boolean;
  onToggle: () => void;
  children?: ReactNode;
  avatar?: ReactNode;
  userMenu?: ReactNode;
};

const LayoutMainToolbar: FC<Props> = props => {
  const { children, open, onToggle, avatar, userMenu } = props;
  return (
    <AppBar position="absolute" open={open}>
      <Toolbar
        sx={{
          pr: '24px', // keep right padding when drawer closed
        }}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={onToggle}
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}>
          <MenuIcon />
        </IconButton>

        <Box sx={{ flexGrow: 1 }}>{children}</Box>

        <ColorModeSwitch />

        <UserArea avatar={avatar} userMenu={userMenu} />
      </Toolbar>
    </AppBar>
  );
};

export default LayoutMainToolbar;
