import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';

import { api, useClientId, useErrorHandler } from 'lib';
import { Requisites, UpdateRequisitesRequest } from 'features';
import { useNotification } from 'components/Notifications';

export const requisitesKeys = {
  all: [{ scope: 'requisites' }] as const,

  details: () => [{ ...requisitesKeys.all[0], entity: 'detail' }] as const,
  detail: (clientId: string) =>
    [{ ...requisitesKeys.details()[0], clientId }] as const,
};

type RequisitesDetailContext = QueryFunctionContext<
  ReturnType<(typeof requisitesKeys)['detail']>
>;

const fetchRequisites = ({
  queryKey: [{ clientId }],
}: RequisitesDetailContext) =>
  api().get<Requisites>(`/billing/${clientId}/details`);

const updateRequisites = ({ clientId, data }: UpdateRequisitesRequest) =>
  api().put(`/billing/${clientId}/details`, data);

export const useRequisites = () =>
  useQuery(requisitesKeys.detail(useClientId()), fetchRequisites);

export const useUpdateRequisites = () => {
  const { pop } = useNotification();
  const { t } = useTranslation('Settings');
  const clientId = useClientId();
  const queryClient = useQueryClient();

  const update = useCallback(
    (data: Requisites) => updateRequisites({ clientId, data }),
    [clientId]
  );

  return useMutation(update, {
    onError: useErrorHandler(),
    onSuccess: data => {
      queryClient.setQueryData(requisitesKeys.detail(clientId), data);
      pop(t('SAVED'), 'success');
    },
  });
};
