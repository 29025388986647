import { ReactNode, useState, useCallback, SyntheticEvent } from 'react';
import { useLocation } from 'react-router';

import Tabs from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from 'components/TabPanel';

type TabDefinition = {
  component: ReactNode;
} & TabProps;

type Props = {
  tabs?: TabDefinition[];
  base?: string;
};

const Tabber = ({ tabs = [], base }: Props) => {
  const location = useLocation();
  const start = Number(location.hash.replace('#tab', ''));
  const [currentIndex, setCurrentIndex] = useState(start);

  const handleTabClick = useCallback(
    (event: SyntheticEvent, i: number) => {
      const path = base ? `${base}${location.pathname}` : location.pathname;

      window.history.replaceState({}, document.title, `${path}#tab${i}`);
      setCurrentIndex(i);
    },
    [location, base]
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentIndex} onChange={handleTabClick}>
          {tabs.map(({ component, ...tab }, i) => (
            <Tab iconPosition="start" {...tab} key={i} />
          ))}
        </Tabs>
      </Box>

      {tabs.map(({ component }, i) => (
        <TabPanel value={currentIndex} key={i} index={i}>
          {component}
        </TabPanel>
      ))}
    </Box>
  );
};

export default Tabber;
