import React, { FC } from 'react';
import { SxProps, Theme } from '@mui/material/styles';

import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

export type DatePickerBaseProps<TInputDate = Date, TDate = Date> = Omit<
  DatePickerProps<TInputDate, TDate>,
  'renderInput'
> & {
  hasError?: boolean;
  error?: string;
  helperText?: string;
  sx?: SxProps<Theme>;
};

const DatePickerBase: FC<DatePickerBaseProps> = props => {
  const { hasError = false, error, helperText, sx, ...rest } = props;

  return (
    <Box sx={sx}>
      <DatePicker
        mask="____-__-__"
        renderInput={params => (
          <TextField
            {...params}
            error={hasError}
            helperText={error || helperText}
            variant="standard"
          />
        )}
        {...rest}
      />
    </Box>
  );
};

export default DatePickerBase;
