import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';

import { Response } from 'lib';
import { DATE_FORMAT } from 'const';
import { useCurrentUser } from 'features';
import { useSelectedClient } from 'components/SelectedClient';
import { useNotification } from 'components/Notifications';

export function formatDate(value: Date) {
  return dayjs(value).format(DATE_FORMAT);
}

export function useIsSuper() {
  const currentUser = useCurrentUser();

  return !!currentUser?.roles?.includes('super');
}

export function useClientId() {
  const clientId = useSelectedClient().client?.id;

  if (!clientId) throw new Error("Current user doesn't have any clients");

  return clientId;
}

export function useClientParams() {
  return {
    client_id: useClientId(),
  };
}

export function useErrorHandler() {
  const { pop } = useNotification();
  const { t } = useTranslation('BackendErrors');

  return (error: AxiosError<Response<any>>) => {
    const responseMessage = error.response?.data.message;
    pop(responseMessage ? t(responseMessage) : error.message, 'error');
  };
}

export * from './useConfirm';
