import Avatar from '@mui/material/Avatar';

type Props = {
  firstName?: string | null;
  lastName?: string | null;
};

function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

function foreColor(hex: string | null) {
  if (!hex) return null;

  const r = parseInt(hex.substring(1, 2), 16);
  const g = parseInt(hex.substring(3, 2), 16);
  const b = parseInt(hex.substring(5, 2), 16);

  const l = 0.299 * r + 0.587 * g + 0.114 * b;

  if (l > 500) {
    return '#000000';
  } else {
    return '#ffffff';
  }
}

const BaseAvatar = ({ firstName, lastName }: Props) => {
  let initials = firstName ? firstName.charAt(0) : null;
  let fullName = firstName ? firstName : '';

  if (lastName) {
    initials += lastName.charAt(0);
    fullName += lastName;
  }

  const bgcolor = fullName ? stringToColor(fullName) : null;
  const color = foreColor(bgcolor);

  return <Avatar sx={{ bgcolor, color }}>{initials}</Avatar>;
};

export default BaseAvatar;
