import React, { forwardRef, ForwardRefRenderFunction, ReactNode } from 'react';

import SaveIcon from '@mui/icons-material/Save';
import MuiFab, { FabProps as MuiFabProps } from '@mui/material/Fab';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

type Props = {
  loading?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
} & MuiFabProps;

export type FabProps = Props;

const Fab: ForwardRefRenderFunction<any, Props> = (props, ref) => {
  const { loading = false, disabled = false, icon, ...rest } = props;

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 50,
        right: 50,
      }}>
      <MuiFab
        color="primary"
        disabled={loading || disabled}
        ref={ref}
        {...rest}>
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          icon || <SaveIcon />
        )}
      </MuiFab>
    </Box>
  );
};

export default forwardRef(Fab);
