import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';

import {
  ClientParams,
  CreateUserInput,
  CreateUserRequest,
  UpdateUserRequest,
  User,
} from 'features';
import { api, useClientParams, useErrorHandler } from 'lib';
import { useNotification } from 'components/Notifications';

export const userKeys = {
  all: [{ scope: 'users' }] as const,

  lists: () => [{ ...userKeys.all[0], entity: 'list' }] as const,
  list: (params: ClientParams) => [{ ...userKeys.lists()[0], params }] as const,

  details: () => [{ ...userKeys.all[0], entity: 'detail' }] as const,
  detail: (id: string, params: ClientParams) => [
    { ...userKeys.details()[0], id, params },
  ],
};

type UserListContext = QueryFunctionContext<
  ReturnType<(typeof userKeys)['list']>
>;

type UserDetailContext = QueryFunctionContext<
  ReturnType<(typeof userKeys)['detail']>
>;

const fetchUsers = ({ queryKey: [{ params }] }: UserListContext) =>
  api('auth').get<User[]>('/users', params);

const fetchUser = ({ queryKey: [{ id, params }] }: UserDetailContext) =>
  api('auth').get<User>(`/users/${id}`, params);

const createUser = ({ params, data }: CreateUserRequest) =>
  api('auth').post<User>('/users', data, {
    generate_password: 1,
    ...params,
  });

const updateUser = ({ id, data }: UpdateUserRequest) =>
  api('auth').put<User>(`/users/${id}`, data);

const deleteUser = (user: User) => api('auth').delete(`/users/${user.id}`);

export const useUsers = () =>
  useQuery(userKeys.list(useClientParams()), fetchUsers);

export const useUser = (id: string) =>
  useQuery(userKeys.detail(id, useClientParams()), fetchUser);

export const useUpdateUser = (id: string) => {
  const { t } = useTranslation('User');
  const { pop } = useNotification();
  const params = useClientParams();
  const queryClient = useQueryClient();

  return useMutation(updateUser, {
    onError: useErrorHandler(),
    onSuccess: data => {
      pop(t('SAVED'), 'success');
      queryClient.setQueryData(userKeys.detail(id, params), data);
    },
  });
};

export const useCreateUser = () => {
  const params = useClientParams();

  const create = useCallback(
    (data: CreateUserInput) => createUser({ params, data }),
    [params]
  );

  return useMutation(create);
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  const queryKey = userKeys.list(useClientParams());
  const onError = useErrorHandler();

  return useMutation(deleteUser, {
    onMutate: (user: User) => {
      const previousUsers = queryClient.getQueryData(queryKey);

      queryClient.setQueryData<User[]>(
        queryKey,
        users => users?.filter(item => item.id !== user.id) ?? []
      );

      return { previousUsers };
    },

    onError: (error: AxiosError<any>, user, context) => {
      onError(error);
      queryClient.setQueryData(queryKey, context?.previousUsers);
    },

    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });
};
