import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import { FormValues } from 'components/AddUsersDialog';
import { useDialog } from 'components/Dialog';
import { useFormContext } from 'react-hook-form';

type Props = {
  loading?: boolean;
  onSubmit: (values: FormValues) => void;
};

const AddUserDialogActions: FC<Props> = ({ loading, onSubmit }) => {
  const { close } = useDialog();
  const { t } = useTranslation('AddUsersDialog');
  const { handleSubmit } = useFormContext<FormValues>();

  return (
    <DialogActions>
      <Button onClick={close} variant="text">
        {t('CANCEL')}
      </Button>

      <LoadingButton
        loading={loading}
        variant="contained"
        startIcon={<PersonAddIcon />}
        onClick={handleSubmit(onSubmit)}>
        {t('ADD')}
      </LoadingButton>
    </DialogActions>
  );
};

export default AddUserDialogActions;
