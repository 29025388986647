import React, {
  createContext,
  FC,
  forwardRef,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from "react";
import { Link, LinkProps } from "react-router-dom";

import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, PaletteMode } from "@mui/material";

const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

type Props = Omit<LinkProps, "to"> & {
  href: LinkProps["to"];
};

const LinkBehavior = forwardRef<any, Props>((props, ref) => {
  const { href, ...other } = props;

  if (!href) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a {...other} />;
  }

  const url = href.toString();

  if (url.startsWith("http")) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a href={url} {...other} />;
  }

  return <Link ref={ref} to={href} {...other} />;
});

export const useColorMode = () => useContext(ColorModeContext).toggleColorMode;

type ThemeProps = {
  children?: ReactNode;
};

const Theme: FC<ThemeProps> = ({ children }) => {
  const [mode, setMode] = useState<PaletteMode>(
    (localStorage.getItem("colorMode") as PaletteMode) ?? "light"
  );

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const mode = prevMode === "light" ? "dark" : "light";

          localStorage.setItem("colorMode", mode);
          return mode;
        });
      },
    }),
    []
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
        shape: {
          borderRadius: 2,
        },
        components: {
          MuiLink: {
            defaultProps: {
              // @ts-ignore
              component: LinkBehavior,
            },
          },
          MuiButtonBase: {
            defaultProps: {
              LinkComponent: LinkBehavior,
            },
          },
        },
      }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <CssBaseline />
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default Theme;
