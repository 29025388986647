import React, {
  FocusEvent,
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  useCallback,
} from 'react';

import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Chip from '@mui/material/Chip';

type Base = AutocompleteProps<string, true, false, true>;

type ErrorObject = {
  message: string;
  type: string;
  ref: any;
};

type Props = Omit<Base, 'options' | 'renderInput' | 'onChange'> & {
  label?: string;
  variant?: TextFieldProps['variant'];
  errors?: ErrorObject | (ErrorObject | undefined)[];
  helperText?: ReactNode;
  onChange?: (value: string[]) => void;
};

export type SimpleChipInputBaseProps = Props;

type ComponentType = ForwardRefRenderFunction<any, Props>;

const SimpleChipInputBase: ComponentType = (props, ref) => {
  const {
    label,
    variant = 'standard',
    errors,
    helperText,
    onChange,
    value,
    ...rest
  } = props;

  const handleChange = useCallback(
    (_event: any, value: string[]) => {
      onChange && onChange(value);
    },
    [onChange]
  );

  const hangleInputBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      const newValue = e.target.value.trim();

      if (!newValue) return;

      onChange && onChange(value ? [...value, newValue] : [newValue]);
    },
    [onChange, value]
  );

  let hasError: boolean;
  let errorText: string | undefined;

  if (Array.isArray(errors)) {
    const filteredErrors = errors.filter(error => error);

    hasError = filteredErrors.length > 0;
    errorText = filteredErrors
      .reduce<string[]>((all, error) => {
        if (error && !all.includes(error.message)) {
          all.push(error.message);
        }

        return all;
      }, [])
      .join(', ');
  } else {
    hasError = !!errors;
    errorText = errors?.message;
  }

  return (
    <Autocomplete<string, true, false, true>
      multiple
      freeSolo
      clearOnBlur
      options={[]}
      value={value}
      onChange={handleChange}
      ref={ref}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="filled"
            color={Array.isArray(errors) && errors[index] ? 'error' : 'default'}
            label={option}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={params => (
        <TextField
          {...params}
          variant={variant}
          label={label}
          error={hasError}
          helperText={hasError ? errorText : helperText}
          onBlur={hangleInputBlur}
        />
      )}
      {...rest}
    />
  );
};

export default forwardRef(SimpleChipInputBase);
