import { FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import Layout from 'components/Layout';
import BaseApp from 'components/App';
import MainMenu from 'components/MainMenu';
import MainToolbar from 'components/MainToolbar';
import Auth from 'components/Auth';
import Avatar from 'components/Avatar';
import UserMenu from 'components/UserMenu';

import { BASE } from 'const';
import { ROUTES } from 'routes';

import logo from 'assets/img/brunas-logo-transparent.png';

const client = new QueryClient();

const App: FC = () => (
  <QueryClientProvider client={client}>
    <ReactQueryDevtools />
    <BaseApp base={BASE} routes={ROUTES}>
      <Auth>
        <Layout
          logo={<img src={logo} alt="" />}
          menu={<MainMenu />}
          avatar={<Avatar />}
          toolbar={<MainToolbar />}
          contentPadding={2}
          userMenu={<UserMenu />}
        />
      </Auth>
    </BaseApp>
  </QueryClientProvider>
);

export default App;
