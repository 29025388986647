import { FC, MouseEvent, useCallback } from 'react';
import { useNavigate } from 'react-router';

import DeleteIcon from '@mui/icons-material/Delete';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { User } from 'features';
import ConfirmableButton from 'components/ConfirmableButton';

type Props = {
  user: User;
  onDelete: (user: User) => void;
};

const UsersTableRow: FC<Props> = ({ user, onDelete }) => {
  const { id, firstName, lastName, email, roles } = user;
  const navigate = useNavigate();

  const handleDeleteClick = useCallback(() => {
    onDelete(user);
  }, [onDelete, user]);

  const handleHandleRowClick = useCallback(
    (event: MouseEvent) => {
      // FIXME: @Cleanup yuk, not sure why event.preventDefault() is not
      // working in handleDeleteClick handler
      if (event.target instanceof HTMLTableCellElement) {
        navigate(`/users/${id}`);
      }
    },
    [id, navigate]
  );

  return (
    <TableRow sx={{ cursor: 'pointer' }} onClick={handleHandleRowClick}>
      <TableCell sx={{ textAlign: 'center' }}>
        {firstName} {lastName}
      </TableCell>

      <TableCell sx={{ textAlign: 'center' }}>{email}</TableCell>
      <TableCell sx={{ textAlign: 'center' }}>{roles?.join(', ')}</TableCell>

      <TableCell sx={{ textAlign: 'center' }}>
        <ConfirmableButton
          confirmSize="small"
          icon={<DeleteIcon fontSize="small" />}
          onClick={handleDeleteClick}
        />
      </TableCell>
    </TableRow>
  );
};

export default UsersTableRow;
