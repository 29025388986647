import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';

import DarkMode from '@mui/icons-material/DarkMode';
import LightMode from '@mui/icons-material/LightMode';
import IconButton from '@mui/material/IconButton';

import { useColorMode } from 'components/Theme';

const ColorModeSwitch: FC = () => {
  const {
    palette: { mode },
  } = useTheme();
  const toggleMode = useColorMode();

  return (
    <IconButton color="inherit" onClick={toggleMode} sx={{ mr: 1 }}>
      {mode === 'dark' ? (
        <LightMode fontSize="small" />
      ) : (
        <DarkMode fontSize="small" />
      )}
    </IconButton>
  );
};

export default ColorModeSwitch;
