import React, { FC, ReactNode } from 'react'
import { BrowserRouter, RouteObject } from 'react-router-dom'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import locale from 'date-fns/locale/lt'

import Theme from 'components/Theme'
import Notifications from 'components/Notifications'
import Router from 'components/Router'
import ErrorBoundary from 'components/ErrorBoundary'

type Props = {
  children: ReactNode
  base?: string
  routes: RouteObject[]
}

const App: FC<Props> = ({ children, base, routes }) => (
  <ErrorBoundary>
    <Theme>
      <Notifications>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={locale}>
          <BrowserRouter basename={base}>
            <Router main={children} routes={routes} />
          </BrowserRouter>
        </LocalizationProvider>
      </Notifications>
    </Theme>
  </ErrorBoundary>
)

export default App
