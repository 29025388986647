import { useTranslation } from 'react-i18next';

import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import Section from 'components/Section';
import Profile from 'components/Profile';
import Password from 'components/Password';
import Breadcrumbs from 'components/Breadcrumbs';
import Tabber from 'components/Tabber';

const Account = () => {
  const { t } = useTranslation('Account');

  return (
    <>
      <Breadcrumbs breadcrumbs={[{ label: 'ACCOUNT' }]} />

      <Box sx={{ display: 'flex' }}>
        <Box>
          <Section>
            <Avatar sx={{ width: 300, height: 300 }} />
          </Section>
        </Box>

        <Paper sx={{ ml: 2, flexGrow: 1 }}>
          <Tabber
            tabs={[
              {
                label: t('PROFILE'),
                component: <Profile />,
                icon: <PersonIcon />,
              },
              {
                label: t('PASSWORD'),
                component: <Password />,
                icon: <LockIcon />,
              },
            ]}
          />
        </Paper>
      </Box>
    </>
  );
};

export default Account;
