import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import LoginIcon from '@mui/icons-material/Login';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/lab/LoadingButton';

import Form from '../Form';
import TextField from '../TextField';
import Checkbox from '../Checkbox';

export type LoginFormValues = {
  email: string;
  password: string;
  remember: boolean;
};

type Props = {
  logo?: ReactNode;
  loading?: boolean;
  remember?: boolean;
  showRememberMe?: boolean;
  showForgotPassword?: boolean;
  onSubmit: (values: LoginFormValues) => void;
};

const schema = yup
  .object({
    email: yup
      .string()
      .email('Neteisingas formatas')
      .required('Prašome įvesti el pašto adresą'),
    password: yup.string().required('Prašome įvesti slaptažodį'),
  })
  .required();

const BaseLogin: FC<Props> = props => {
  const {
    logo,
    loading = false,
    onSubmit,
    remember = false,
    showRememberMe = false,
    showForgotPassword = false,
  } = props;
  const { t } = useTranslation('Login');

  const initial = {
    email: '',
    password: '',
    remember,
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        {logo}

        <Box sx={{ mt: 1 }}>
          <Form<LoginFormValues>
            schema={schema}
            initial={initial}
            onSubmit={onSubmit}>
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label={t('EMAIL')}
              name="email"
              autoComplete="email"
              autoFocus
            />

            <TextField
              margin="normal"
              fullWidth
              name="password"
              label={t('PASSWORD')}
              type="password"
              id="password"
              autoComplete="current-password"
            />

            {showRememberMe && (
              <FormControlLabel
                control={
                  <Checkbox name="remember" value={true} color="primary" />
                }
                label={t('REMEMBER_ME')}
              />
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              loading={loading}
              sx={{ mt: 3, mb: 2 }}
              startIcon={<LoginIcon />}>
              {t('LOGIN')}
            </Button>
          </Form>

          {showForgotPassword && (
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  {t('FORGOT_PASSOWORD')}
                </Link>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default BaseLogin;
