import { FC, ReactNode } from 'react';
import { RouteObject, useRoutes } from 'react-router';

type Props = {
  main: ReactNode;
  routes: RouteObject[];
};

const Router: FC<Props> = ({ main, routes }) =>
  useRoutes([
    {
      path: '/',
      element: main,
      children: routes,
    },
  ]);

export default Router;
