import { ChangeEvent, useCallback, useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import TableSkeleton from 'components/TableSkeleton';
import Section from 'components/Section';
import OrderInfoTable from 'components/OrderInfoTable';
import ActionBox from 'components/ActionBox';

import { createOrder, useOrderPreview } from 'features';
import { useClientParams, useErrorHandler } from 'lib';
import { useNotification } from 'components/Notifications';
import { ProductsContext } from 'routes/Products';

const ConfirmOrder = () => {
  const { t } = useTranslation('ConfirmOrder');
  const { pop } = useNotification();
  const [orderNr, setOrderNr] = useState<string | null>(null);
  const onError = useErrorHandler();
  const params = useClientParams();

  const {
    submitted,
    userComment,
    getOrderItems,
    setUserComment,
    setActiveStep,
  } = useContext(ProductsContext);

  const items = getOrderItems();
  const { data } = useOrderPreview(items);

  const mutation = useMutation(createOrder, {
    onError,
  });

  const handleUserCommentChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setUserComment(event.currentTarget.value);
    },
    [setUserComment]
  );

  const handleConfirmOrderClick = useCallback(() => {
    const data = {
      userComment,
      items,
    };

    mutation.mutate(
      { data, params },
      {
        onSuccess: order => {
          if (!order) {
            pop(t('NO_ORDER_DATA'), 'error');
            return;
          }

          setActiveStep(2);
          setOrderNr(order.number);

          pop(
            t('SUBMITTED_SUCCESSFULLY', { orderNr: order.number }),
            'success'
          );
        },
      }
    );
  }, [items, mutation, params, pop, setActiveStep, t, userComment]);

  return (
    <>
      {data ? (
        <Section title={t('ORDER_INFORMATION')} sx={{ mb: 1 }}>
          <OrderInfoTable order={data} />
        </Section>
      ) : (
        <TableSkeleton cols={4} rows={3} />
      )}

      <Section
        title={t('ADDITIONAL_INFORMATION')}
        subTitle={t('ADDITIONAL_INFORMATION_SUBTITLE')}>
        <TextField
          multiline
          rows={10}
          disabled={submitted}
          sx={{ width: '100%' }}
          value={userComment}
          onChange={handleUserCommentChange}
        />
      </Section>

      <ActionBox>
        {submitted ? (
          <Alert severity="success" sx={{ width: '100%' }}>
            {t('SUBMITTED_SUCCESSFULLY', { orderNr })}
          </Alert>
        ) : (
          <LoadingButton
            variant="contained"
            loading={mutation.isLoading}
            startIcon={<ShoppingCartIcon />}
            onClick={handleConfirmOrderClick}>
            {t('CONFIRM_ORDER')}
          </LoadingButton>
        )}
      </ActionBox>
    </>
  );
};

export default ConfirmOrder;
