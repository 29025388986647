import { useCallback } from 'react';
import {
  useController,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';

import SimpleChipInputBase, {
  SimpleChipInputBaseProps,
} from 'components/SimpleChipInputBase';

type Props = SimpleChipInputBaseProps & UseControllerProps;

const SimpleChipInput = (props: Props) => {
  const { setValue, control } = useFormContext();

  const {
    field: { onChange, ...field },
    fieldState,
  } = useController({ control, ...props });

  const handleChange = useCallback(
    (value: string[]) => {
      setValue(props.name, value);
    },
    [props.name, setValue]
  );

  return (
    <SimpleChipInputBase
      onChange={handleChange}
      errors={(fieldState?.error ?? []) as any}
      {...field}
      {...props}
    />
  );
};

export default SimpleChipInput;
