import { ReactNode } from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Drawer from '../Drawer';

type Props = {
  open: boolean;
  onToggle: () => void;
  logo?: ReactNode;
  children?: ReactNode;
};

const LayoutMainMenu = ({ logo, open, onToggle, children }: Props) => (
  <Drawer variant="permanent" open={open}>
    <Toolbar
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        px: [1],
      }}>
      <Box
        sx={{
          flexGrow: 1,
          pt: 1,
          pl: 1,
          '& img': { maxHeight: '100%', maxWidth: '100%' },
        }}>
        {logo}
      </Box>

      <IconButton onClick={onToggle}>
        <ChevronLeftIcon />
      </IconButton>
    </Toolbar>

    <Divider />

    {children}
  </Drawer>
);

export default LayoutMainMenu;
