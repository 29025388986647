import React, {
  MouseEvent,
  ReactNode,
  useCallback,
  useState,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react';

import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ConfirmIcon, { FontSize } from '../ConfirmableButtonConfirmIcon';

type Props = IconButtonProps & {
  icon: ReactNode;
  confirmIcon?: ReactNode;
  confirmSize?: FontSize;
  confirm?: boolean;
  tooltip?: string;
};

const ConfirmableButton: ForwardRefRenderFunction<any, Props> = (
  props,
  ref
) => {
  const {
    onClick,
    icon,
    confirmIcon,
    confirmSize,
    confirm = true,
    tooltip,
    ...rest
  } = props;

  const [confirming, setConfirming] = useState(false);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      if (onClick && (!confirm || confirming)) {
        onClick(event);
      } else {
        setConfirming(true);
        setTimeout(() => {
          setConfirming(false);
        }, 2000);
      }
    },
    [confirm, confirming, onClick]
  );

  return (
    <IconButton
      color="error"
      size="small"
      sx={{ minWidth: 20 }}
      {...rest}
      onClick={handleClick}
      ref={ref}>
      {confirming ? (
        <ConfirmIcon
          icon={confirmIcon}
          fontSize={confirmSize}
          tooltip={tooltip}
        />
      ) : (
        icon
      )}
    </IconButton>
  );
};

export default forwardRef(ConfirmableButton);
