import React, { forwardRef, ForwardRefRenderFunction, ReactNode } from 'react';

import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';

export type SelectOption<V extends string | number = string | number> = {
  value?: V;
  label: string;
  color?: string;
  options?: SelectOption<V>[];
};

type Props = {
  label?: ReactNode;
  hasError?: boolean;
  errorMessage?: string;
  helperText?: ReactNode;
  options: SelectOption[];
} & MuiSelectProps;

export type SelectBaseProps = Props;

const makeMenu = (opts: SelectOption[]) => {
  const result = [];

  let key = 0;
  for (let option of opts) {
    if (option.options) {
      result.push(<ListSubheader key={key++}>{option.label}</ListSubheader>);

      for (let second of option.options) {
        result.push(
          <MenuItem key={key++} value={second.value}>
            <Box color={second.color}>{second.label}</Box>
          </MenuItem>
        );
      }
    } else {
      result.push(
        <MenuItem key={key++} value={option.value}>
          <Box color={option.color}>{option.label}</Box>
        </MenuItem>
      );
    }
  }

  return result;
};

const SelectBase: ForwardRefRenderFunction<any, Props> = (props, ref) => {
  const {
    label,
    hasError,
    options,
    helperText,
    errorMessage,
    value = '',
    fullWidth,
    sx,
    ...rest
  } = props;

  const labelId = `${props.name}-label`;

  return (
    <FormControl fullWidth={fullWidth} sx={sx} variant="standard">
      {label && <InputLabel id={labelId}>{label}</InputLabel>}

      <MuiSelect
        {...rest}
        ref={ref}
        value={value}
        labelId={labelId}
        error={hasError}>
        {makeMenu(options)}
      </MuiSelect>

      {(hasError || helperText) && (
        <FormHelperText error={hasError}>
          {hasError ? errorMessage : helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default forwardRef(SelectBase);
