import { useMutation } from 'react-query';

import BaseLogin from 'components/BaseLogin';

import { useErrorHandler, api } from 'lib';
import { getCookieDomain } from 'const';
import { AuthTokens } from 'features';

import logo from 'assets/img/brunas-logo-transparent.png';

type Credentials = {
  email: string;
  password: string;
};

const Login = () => {
  const onError = useErrorHandler();

  const mutation = useMutation(
    async (credentials: Credentials) =>
      await api('auth').post<AuthTokens>(
        '/auth/login',
        {
          ...credentials,
          login_type: 'email_password',
        },
        {
          cookie_domain: getCookieDomain()!,
        }
      ),
    {
      onError,
      onSuccess: () => {
        window.location.reload();
      },
    }
  );

  return (
    <BaseLogin
      logo={<img src={logo} alt="Brunas" />}
      loading={mutation.isLoading}
      onSubmit={mutation.mutate}
    />
  );
};

export default Login;
