import { useCallback } from 'react';
import {
  useController,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';

import RolesInputBase from 'components/RolesInputBase';

type Props = UseControllerProps;

const RolesInput = (props: Props) => {
  const { setValue, control } = useFormContext();

  const {
    field: { value },
  } = useController({ control, ...props });

  const handleChange = useCallback(
    (value: string[]) => {
      setValue(props.name, value);
    },
    [props.name, setValue]
  );

  return <RolesInputBase value={value} onChange={handleChange} />;
};

export default RolesInput;
