import React, { FC } from 'react';
import {
  useController,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';

import MuiTextField, { TextFieldProps } from '@mui/material/TextField';

type Props = TextFieldProps & UseControllerProps;

const TextField: FC<Props> = props => {
  const { control } = useFormContext();

  const {
    field,
    fieldState: { error },
  } = useController({ control, ...props });

  return (
    <MuiTextField
      {...field}
      {...props}
      variant="standard"
      error={Boolean(error)}
      helperText={error?.message}
    />
  );
};

export default TextField;
