import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { Outlet } from 'react-router-dom';

import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import ErrorBoundary from 'components/ErrorBoundary';
import LayoutMainToolbar from 'components/LayoutMainToolbar';
import LayoutMainMenu from 'components/LayoutMainMenu';

type Props = {
  logo?: ReactNode;
  menu: ReactNode;
  toolbar?: ReactNode;
  avatar?: ReactNode;
  userMenu?: ReactNode;
  contentPadding?: number;
};

type LayoutContext = {
  mainMenuOpen: boolean;
};

const Context = createContext<LayoutContext>({
  mainMenuOpen: false,
});

export const useLayout = () => useContext(Context);

const Layout = (props: Props) => {
  const [open, setOpen] = useState(localStorage.getItem('mainMenuOpen') ?? '1');
  const { logo, menu, toolbar, avatar, contentPadding, userMenu } = props;

  const toggleDrawer = useCallback(() => {
    setOpen(v => {
      const newValue = v === '1' ? '0' : '1';

      localStorage.setItem('mainMenuOpen', newValue);

      return newValue;
    });
  }, []);

  const mainMenuOpen = open === '1';

  return (
    <Context.Provider value={{ mainMenuOpen }}>
      <Box sx={{ display: 'flex' }}>
        <LayoutMainToolbar
          open={mainMenuOpen}
          onToggle={toggleDrawer}
          avatar={avatar}
          userMenu={userMenu}>
          {toolbar}
        </LayoutMainToolbar>

        <LayoutMainMenu logo={logo} open={open === '1'} onToggle={toggleDrawer}>
          {menu}
        </LayoutMainMenu>

        <Box
          component="main"
          sx={{
            backgroundColor: theme =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            color: 'text.primary',
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            p: contentPadding,
          }}>
          <Toolbar />

          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </Box>
      </Box>
    </Context.Provider>
  );
};

export default Layout;
