import React, { FC, ReactNode } from 'react';

type Props = {
  value: number;
  index: number;
  children?: ReactNode;
};

const TabPanel: FC<Props> = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <>{children}</>}
    </div>
  );
};

export default TabPanel;
