import React, { FC, ReactElement, useCallback } from 'react';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

type Props = {
  fallback?: ReactElement;
  children?: ReactElement;
};

const ErrorBoundary: FC<Props> = ({ fallback, children }) => {
  const { t } = useTranslation();

  const handleReloadClick = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <SentryErrorBoundary
      fallback={
        fallback ?? (
          <Alert
            severity="error"
            action={
              <Button color="inherit" size="small" onClick={handleReloadClick}>
                {t('Reload')}
              </Button>
            }>
            {t('Oops, application encountered an error')}
          </Alert>
        )
      }>
      {children}
    </SentryErrorBoundary>
  );
};

export default ErrorBoundary;
