import React, { FC, ReactNode } from 'react';

type Props = {
  loggedIn: boolean;
  children: ReactNode;
  loginComponent: ReactNode;
};

const AuthGuard: FC<Props> = ({ loggedIn, children, loginComponent }) => (
  <>{loggedIn ? children : loginComponent}</>
);

export default AuthGuard;
