import React, { FC, ReactNode, useCallback } from 'react';

import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import UserAreaMenu from 'components/UserAreaMenu';

type Props = {
  avatar?: ReactNode;
  userMenu?: ReactNode;
};

const UserArea: FC<Props> = ({ avatar, userMenu }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        {avatar ?? <Avatar />}
      </IconButton>

      <UserAreaMenu anchorEl={anchorEl} onClose={handleClose} menu={userMenu} />
    </>
  );
};

export default UserArea;
