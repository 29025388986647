import React, { ReactNode } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AnyObjectSchema } from 'yup';
import { SxProps, Theme } from '@mui/material/styles';

import Box from '@mui/material/Box';

type Props<F> = {
  schema?: AnyObjectSchema;
  onSubmit: (values: F) => void;
  initial: F;
  children: ReactNode;
  sx?: SxProps<Theme>;
};

function Form<F extends FieldValues>(props: Props<F>) {
  const { schema, onSubmit, initial, children, sx } = props;

  const form = useForm<F>({
    resolver: schema && yupResolver(schema),
    defaultValues: initial as any,
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit as any)} noValidate>
        <Box sx={sx}>{children}</Box>
      </form>
    </FormProvider>
  );
}

export default Form;
